// ** Auth Endpoints
export default {
  // login:"http://ec2-13-115-247-194.ap-northeast-1.compute.amazonaws.com/api/admin/login",
  login:"/api/admin/login",
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageData: 'userData',

  /**Company User */
  employee_create:"/api/v2/initial-admin/user-create",
  employee_list:"/api/v2/initial-admin/employee-user-list/0/10" 
}
