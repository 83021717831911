import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import { notifyError } from "../../../utility/Utils";

const excludeUrls = ["/api/v2/service-admin/subadmin-create"];

export default class JwtService {
  // axios.defaults.baseURL="http://ec2-13-115-247-194.ap-northeast-1.compute.amazonaws.com";
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    // axios.defaults.baseURL = "http://ec2-13-115-247-194.ap-northeast-1.compute.amazonaws.com";
    //axios.defaults.baseURL = "https://api.uat.go-smart.app";
    axios.defaults.baseURL = "https://api.qa.go-smart.app";
    //axios.defaults.baseURL = "https://api.dev.go-smart.app";
    // axios.defaults.baseURL = "http://52.195.1.181";

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = JSON.parse(this.getToken());

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9lYzItMTMtMTE1LTI0Ny0xOTQuYXAtbm9ydGhlYXN0LTEuY29tcHV0ZS5hbWF6b25hd3MuY29tXC9hcGlcL3YyXC9zZXJ2aWNlLWFkbWluXC9jb21wYW5pZXMtbGlzdFwvMFwvMTAiLCJpYXQiOjE2NDUwMzkwNDIsImV4cCI6MTY0NTEyNTQ0MiwibmJmIjoxNjQ1MDM5MDQyLCJqdGkiOiJkZEZTdkRZQjBXUkg2WUJNIiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.kLGTpLLsmcgYQz_jT3jWACMKBMbUMSL8arO67u36mZ0'}`
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    let hasShownLogoutToast = false;
    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;
        // if (!excludeUrls.includes(config.url)) {
        //   if (response?.data?.message) {
        //     // notifyError(response?.data.message);
        //   } else {
        //     // notifyError("something went wrong");
        //   }
        // }

        // ** if (status === 401) {
        // if (response && response.status === 401) {
        //   if (!this.isAlreadyFetchingAccessToken) {
        //     this.isAlreadyFetchingAccessToken = true;
        //     this.refreshToken().then((r) => {
        //       this.isAlreadyFetchingAccessToken = false;

        //       // ** Update accessToken in localStorage
        //       this.setToken(r.data.accessToken);
        //       this.setRefreshToken(r.data.refreshToken);

        //       this.onAccessTokenFetched(r.data.accessToken);
        //     });
        //   }
        //   const retryOriginalRequest = new Promise((resolve) => {
        //     this.addSubscriber((accessToken) => {
        //       // ** Make sure to assign accessToken according to your response.
        //       // ** Check: https://pixinvent.ticksy.com/ticket/2413870
        //       // ** Change Authorization header
        //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        //       resolve(this.axios(originalRequest));
        //     });
        //   });
        //   return retryOriginalRequest;
        // }
        if(response && response.status === 401 ){
          if(this.jwtConfig.storageData && !hasShownLogoutToast){
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageData)
          notifyError("セキュリティ確保の理由によりログアウトされました。恐れ入りますが再度ログインしてください ")
          hasShownLogoutToast = true;
          setTimeout(
            () => {window.location.replace("/login"),
            hasShownLogoutToast = false},
            3000
          );
          }
          return
        }

        if(response && response.status === 403){
          if(this.jwtConfig.storageData && !hasShownLogoutToast){
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageData)
          notifyError(response.data.message)
          hasShownLogoutToast = true;
          setTimeout(
            () => {window.location.replace("/login")
            hasShownLogoutToast = false},
            3000
          );
          }
          return
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.login, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  /**Employee user */
  addEmployee(...args) {
    return axios.post(this.jwtConfig.employee_create, ...args);
  }

  getEmployeeList() {
    return axios.get(this.jwtConfig.employee_list);
  }
}
