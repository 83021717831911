import Avatar from '@components/avatar'
import { isNumber } from 'lodash';
import { Fragment } from 'react'
import { Check, X } from "react-feather";
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#42b0ee', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})


export const getAllStatus = [
  { value: 0, label: "Pending", label_en: "Pending", label_jp: "保留中" },
  { value: 1, label: "Approve", label_en: "Approve", label_jp: "承認" },
  { value: 2, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" }];


  export const getAdminStatus = [
    { value: 0, label: "Invited", label_en: "Invited", label_jp: "招待中" },
    { value: 1, label: "Approve", label_en: "Logged in", label_jp: "ログイン済み" },
    { value: 2, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" }];

export const getEmployeeLabelByStatus = (status, lang = 'en') => {
  const currentObj = getAllStatus.find(resp => resp.value === status);
  if (currentObj) {
    return currentObj['label_' + lang];
  }
}
export const getAdminLabelByStatus = (status, lang = 'en') => {
  const currentObj = getAdminStatus.find(resp => resp.value === status);
  if (currentObj) {
    return currentObj['label_' + lang];
  }
}

export const getTimeInMinutes = (max = 1440, interval = 30, start = 0) => {
  let time = [];
  for (let i = start; i <= max; i++) {
    if (i % interval == 0) {
      time.push(i);
    }
  }
  return time;
}


export const SuccessToast = ({ msg }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Check size={12} />} />
          {/* <h6 className='toast-title'>{t('toast.success')}</h6> */}
          <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {msg}
        </span>
      </div>
        </div>
      </div>
      {/* <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {msg}
        </span>
      </div> */}
    </Fragment>
  )
}

export const notifySuccess = (msg) => toast.success(<SuccessToast msg={msg} />, { icon: false, hideProgressBar: true })

export const holidays = ["M", "T", "W", "T", "F", "S", "S"];

export const convertMinutesToHours = (time) => {
  const hours = time / 60;
  return hours;
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const statusObj = {
  pending: 'light-warning',
  active: 'light-success',
  inactive: 'light-secondary'
}

export const USER_TYPES = {
  COMPANY_ADMIN: "company_admin",
  OPERATOR_ADMIN: "operator_admin",
  INITIAL_ADMIN: "initial_admin",
  EMPLOYEE: "employee",
  SUBADMIN : "subadmin"
}

export const getAdminUsers = (data) => {
  return data.filter(value => value.user_type === USER_TYPES.OPERATOR_ADMIN || value.user_type === USER_TYPES.INITIAL_ADMIN||value.user_type === USER_TYPES.SUBADMIN );
}

export const getPaginationParams = (currentPage, rowsPerPage) => {
  const from = (currentPage - 1) * rowsPerPage;
  const to = rowsPerPage;
  return { from, to };
}
export const getPaginationParamsWithOutRows = (currentPage, rowsPerPage) => {
  const from = (currentPage - 1) * rowsPerPage;
  const to = currentPage*rowsPerPage;
  return { from, to };
}


export const approvalStatusObj = {
  0: 'saved-badge',
  1: 'approve-badge ',
  2: 'suspended-badge',
  3: 'pending-badge',
  4: 'suspended-badge'
}
export const deletedStatus = {
  0: 'Relieve',
}
export const approvalAdminStatusObj = {
  0: 'pending-badge',
  1: 'approve-badge ',
  2: 'suspended-badge',
  4: 'suspended-badge'
}

export const years = [2020, 2021, 2022, 2023, 2024, 2025];

const ErrorToast = ({ msg }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='danger' icon={<X size={12} />} />
          {/* <h6 className='toast-title'>{t('toast.error')}</h6> */}
          <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {msg}
        </span>
      </div>
        </div>
      </div>
      {/* <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {msg}
        </span>
      </div> */}
    </Fragment>
  )
}

export const notifyError = (msg) => toast.error(<ErrorToast msg={msg} />, { icon: false, hideProgressBar: true })


export const printDiv = (divName) => {
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  document.body.innerHTML = originalContents;
  window.location.reload();
  
}

export const getLabelStatus = [
  { value: 0, label: "Saved", label_en: "Saved", label_jp: "保存" },
  { value: 1, label: "Logined", label_en: "Logined", label_jp: "ログイン済" },
  { value: 2, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" },
  { value: 3, label: "Invited", label_en: "Invited", label_jp: "招待中" },
  { value: 4, label: "Suspend", label_en: "Suspend", label_jp: "一時停止" },];

export const getDeletedStatus = [
  { value: 1, label: "Saved1", label_en: "Relieve", label_jp: "退職済み" },
  ];

  export const getDeletedByStatus = (status, lang = 'en') => {
    const currentObj = getDeletedStatus.find(resp => resp.value === status);
    if (currentObj) {
      return currentObj['label_' + lang];
    }
  }

export const getLabelByStatus = (status, lang = 'en') => {
  const currentObj = getLabelStatus.find(resp => resp.value === status);
  if (currentObj) {
    return currentObj['label_' + lang];
  }
}
export const getLabelStatusPending = [
  { value: 0, label: "Pending", label_en: "Pending", label_jp: "承認待ち" },
  { value: 1, label: "Approved", label_en: "Approved", label_jp: "ログインしました" },
  { value: 2, label: "Rejected", label_en: "Rejected", label_jp: "不認可" }];


export const getLabelByStatusPending = (status, lang = 'en') => {
  const currentObj = getLabelStatusPending.find(resp => resp.value === status);
  if (currentObj) {
    return currentObj['label_' + lang];
  }
}

export const holidaysList = [
  {
    id:1,
    label: "月",
    value: "monday"
  },
  {
    id:2,
    label: "火",
    value: "tuesday"
  },
  {
    id:3,
    label: "水",
    value: "wednesday"
  },
  {
    id:4,
    label: "木",
    value: "thursday"
  },
  {
    id:5,
    label: "金",
    value: "friday"
  },
  {
    id:6,
    label: "土",
    value: "saturday"
  },
  {
    id:7,
    label: "日",
    value: "sunday"
  },
  {
    id:8,
    label: "祝",
    value: "public_holiday"
  }
]

/**user management */
export const userManagementStatus = {
  0: 'pending-approval-badge',
  1: 'approve-badge ',
  2: 'pending-approval-reject'
}


export const currencyConverter = (number) => {
     
  return isNumber(number)?new Intl.NumberFormat('ja-JP', { currency: 'JPY' }).format(number):0;
}

export const getTimeList = (max = 1410, interval = 30, start = 0) => {
  let time = [];
  var hours, minutes;
  for (var i = 0; i <= 1410; i += 30) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    if (hours < 10) {
      hours = "0" + hours; // adding leading zero
    }
    time.push(hours + ":" + minutes);
  }
  return time;
};